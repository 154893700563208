// @flow
import type { Styles } from './types';

const styles: Styles = theme => ({
  root: {
    display: 'grid',
    gap: `${theme.spacing(1)}px`,
    [theme.breakpoints.up('sm')]: {
      gridTemplateColumns: 'repeat(12, 1fr)',
    },
    [theme.breakpoints.up('md')]: {
      gridTemplateRows: 'repeat(2, 260px)',
    },
  },
  item: ({ stories }) => ({
    gridColumn: 'auto',
    [theme.breakpoints.up('sm')]: {
      gridColumn: 'span 4',
      ...(stories && stories.length < 2
        ? {
            '&:nth-child(1)': {
              gridColumnStart: 1,
              gridColumnEnd: 13,
              gridRowStart: 1,
              gridRowEnd: 3,
            },
          }
        : {
            '&:nth-child(1)': {
              gridColumnStart: 1,
              gridColumnEnd: 9,
              gridRowStart: 1,
              gridRowEnd: 3,
            },
            '&:nth-child(2)': {
              gridColumnStart: 9,
              gridColumnEnd: 13,
            },
            '&:nth-child(3)': {
              gridColumnStart: 9,
              gridColumnEnd: 13,
            },
            '&:nth-child(4)': {
              gridColumnStart: 1,
              gridColumnEnd: 7,
            },
            '&:nth-child(5)': {
              gridColumnStart: 7,
              gridColumnEnd: 13,
            },
          }),
    },
  }),
  itemTitle: {
    [theme.breakpoints.up('sm')]: {
      fontSize: theme.typography.h3.fontSize,
    },
  },
  itemSubtitle: {
    [theme.breakpoints.up('sm')]: {
      fontSize: theme.typography.h5.fontSize,
    },
  },
});

export default styles;
