// @flow
import * as React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import classnames from 'classnames';
import map from 'lodash/map';

import StoryCard from '~components/StoryCard';

import type { Props } from './types';
import styles from './styles';

const HighlightedStories = ({
  stories,
  className,
  classes,
  ...props
}: Props): React.Node => {
  return (
    <div className={classnames(classes.root, className)} {...props}>
      {map(stories, (story, index: number) => (
        <div key={index} className={classes.item}>
          <StoryCard
            node={story}
            title={story?.data?.title}
            subtitle={story?.data?.subtitle?.text}
            linkLabel={story?.data?.title?.text}
            tag={story?.data?.story_tag}
            backgroundImage={story?.data?.background_image?.url}
            {...(index === 0
              ? {
                  classes: {
                    title: classes.itemTitle,
                    subtitle: classes.itemSubtitle,
                  },
                }
              : null)}
          />
        </div>
      ))}
    </div>
  );
};

HighlightedStories.defaultProps = {
  className: undefined,
};

export default withStyles<*, *, Props>(styles)(HighlightedStories);
