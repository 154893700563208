// @flow
import * as React from 'react';
import { graphql } from 'gatsby';
import compose from 'recompose/compose';
import withStyles, {
  type WithStyles,
} from '@material-ui/core/styles/withStyles';
import type { Theme } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import sortBy from 'lodash/fp/sortBy';
import filter from 'lodash/fp/filter';
import reverse from 'lodash/fp/reverse';
import fp$compose from 'lodash/fp/compose';
import concat from 'lodash/concat';

import withNodePage, {
  type WithNodePageProps,
} from '~plugins/prismic/hocs/withNodePage';
import HighlightedStories from '~components/HighlightedStories';
import StoryCard from '~components/StoryCard';
import type { PrismicAllStoriesPage } from '~schema';

export type ClassKey =
  | 'root'
  | 'highlgihtedBodySection'
  | 'bodySection'
  | 'bodySectionItem'
  | 'cardWrapper'
  | 'cardTitle'
  | 'cardSubtitle';

export type Props = {
  ...$Exact<WithStyles<ClassKey>>,
  ...$Exact<WithNodePageProps<PrismicAllStoriesPage>>,
};

const styles = (theme: Theme) => ({
  root: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
    marginTop: 51,
    [theme.breakpoints.up('lg')]: {
      marginTop: 89,
    },
  },
  highlgihtedBodySection: {
    marginBottom: theme.spacing(1),
  },
  bodySection: {},
  bodySectionItem: {
    [theme.breakpoints.up('md')]: {
      '&:nth-child(7n)': {
        maxWidth: '100%',
        flexBasis: '100%',
        '& $cardWrapper': {
          [theme.breakpoints.up('md')]: {
            maxWidth: theme.breakpoints.values.md,
          },
        },
        '& $cardTitle': {
          [theme.breakpoints.up('md')]: {
            fontSize: theme.typography.h3.fontSize,
          },
        },
        '& $cardSubtitle': {
          [theme.breakpoints.up('md')]: {
            fontSize: theme.typography.h5.fontSize,
          },
        },
      },
    },
  },
  cardWrapper: {},
  cardTitle: {},
  cardSubtitle: {},
});

const StoriesPage = ({ classes, data }: Props) => {
  const stories = data?.allPrismicStory?.nodes;
  const sortStoriesWithRelevance = fp$compose(
    sortBy(story => story?.data?.story_relevance),
    filter(story => story?.data?.story_relevance),
  );
  const sortStoriesWithoutRelevance = fp$compose(
    reverse,
    sortBy(story => story?.data?.date || story?.first_publication_date),
    filter(story => !story?.data?.story_relevance),
  );
  const storiesWithRelevance = stories
    ? sortStoriesWithRelevance(stories)
    : undefined;
  const storiesWithoutRelevance = stories
    ? sortStoriesWithoutRelevance(stories)
    : undefined;
  const sortedStories = concat(storiesWithRelevance, storiesWithoutRelevance);

  const sortedStoriesWithoutHiddenTag = sortedStories.filter(
    story => story && !story.tags.includes('hidden'),
  );

  const hightLightedStories = sortedStoriesWithoutHiddenTag.slice(0, 5);
  const otherStories = sortedStoriesWithoutHiddenTag.slice(5);

  return (
    <div className={classes.root}>
      <HighlightedStories
        stories={hightLightedStories}
        className={classes.highlgihtedBodySection}
      />
      <Grid container spacing={1} className={classes.bodySection}>
        {otherStories.map((story, index: number) => {
          return (
            <Grid
              key={index || story?.data?.title?.text}
              item
              xs={12}
              sm={6}
              md={4}
              className={classes.bodySectionItem}
            >
              <StoryCard
                node={story}
                title={story?.data?.title}
                subtitle={story?.data?.subtitle?.text}
                linkLabel={story?.data?.title?.text}
                tag={story?.data?.story_tag}
                backgroundImage={story?.data?.background_image?.url}
                classes={{
                  content: classes.cardWrapper,
                  title: classes.cardTitle,
                  subtitle: classes.cardSubtitle,
                }}
              />
            </Grid>
          );
        })}
      </Grid>
    </div>
  );
};

export default compose(
  withNodePage<PrismicAllStoriesPage, *>({
    getNode: data => data?.prismicAllStoriesPage,
  }),
  withStyles<ClassKey, *, Props>(styles),
)(StoriesPage);

export const query = graphql`
  query AllStoriesPageQuery($prismicId: ID, $prismicLocaleId: String) {
    prismicAllStoriesPage(prismicId: { eq: $prismicId }) {
      id
      lang
      first_publication_date
      last_publication_date
      tags
      data {
        meta_title {
          text
        }
        meta_description {
          text
        }
        meta_keywords {
          meta_keyword {
            text
          }
        }
        meta_image {
          alt
          url
          dimensions {
            width
            height
          }
        }
        name {
          text
        }
        stories_tag {
          id
          document {
            ... on PrismicStoryTag {
              id
              data {
                name {
                  text
                  html
                }
              }
            }
          }
        }
        appbar_relation {
          type
          link_type
          url
          document {
            ... on PrismicAppbarSecondary {
              data {
                appbar_actions {
                  action_label {
                    text
                  }
                  action_link {
                    type
                    link_type
                    url
                  }
                  action_link_section_title {
                    text
                  }
                  action_menu_relation {
                    type
                    link_type
                    url
                    document {
                      ... on PrismicMenu {
                        id
                        data {
                          menu_actions {
                            action_label {
                              text
                              html
                            }
                            action_link {
                              type
                              link_type
                              url
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    allPrismicStory(filter: { lang: { eq: $prismicLocaleId } }) {
      nodes {
        id
        uid
        type
        lang
        first_publication_date
        tags
        data {
          title {
            text
            html
          }
          subtitle {
            text
            html
          }
          background_image {
            alt
            url
            fluid(maxWidth: 1800) {
              ...GatsbyPrismicImageFluid
            }
          }
          date
          story_relevance
          story_tag {
            id
            document {
              ... on PrismicStoryTag {
                id
                data {
                  name {
                    text
                    html
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
